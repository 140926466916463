/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-useless-constructor */
class MainNavigation extends HTMLElement {
  constructor() {
      super();
  }

  static get observedAttributes() {
      return ['data-active-category'];
  }

  connectedCallback() {
      this.getElements();
      this.setEvents();
      this.observeControls();
      // Hier Client-Side-Rendering Flyout
      if (MainNavigation.isDesktop().matches) {
          this.initFlyout();
      }
  }

  attributeChangedCallback(name, oldValue, newValue) {
      if (oldValue !== null) {
      // Render Flyout with data-active-category value
          this.renderFlyout(newValue);
      }
  }

  getElements() {
      this.mainNavContainer = this.querySelector('.off-canvas');
      // this.navigation = this.querySelector('nav');
      this.mainNav = this.querySelector('.main-nav');
      this.mainNavItems = this.querySelectorAll('.item');
      this.next = this.querySelector('.next');
      this.prev = this.querySelector('.prev');
  }

  setEvents() {
      this.next.addEventListener('click', () => { this.handleClickNext(); });
      this.prev.addEventListener('click', () => { this.handleClickPrev(); });
      this.setUpScrollEvents();
  }

  setUpScrollEvents() {
      let ticking;
      this.mainNav.addEventListener("scroll", () => {
          this.resetControllVisibility();
          if (!ticking) {
              window.requestAnimationFrame(() => {
                  this.updateVisibiltyThruScrollPos();
                  ticking = false;
              });
              ticking = true;
          }
      });
  }

  resetControllVisibility() {
      this.next.style.display = 'block';
      this.prev.style.display = 'block';
  }

  updateVisibiltyThruScrollPos() {
      if (this.mainNav.scrollLeft <= 0) {
          this.prev.style.display = 'none';
      }
      if (this.mainNav.scrollLeft >= (this.mainNav.scrollWidth - this.mainNav.clientWidth - 1)) {
          this.next.style.display = 'none';
      }
  }

  async initFlyout() {
      this.url = this.dataset.categories;
      this.categoryData = await this.getFlyoutData(this.url);
      this.categories = this.categoryData.categories;
      this.setFlyoutEvents();
  }

  async getFlyoutData(url) {
      try {
          this.result = await fetch(url, {
              method: 'GET',
              headers: {
                  Accept: 'application/json'
              }
          })
          .then((res) => {
              return res.json();
          })
          .then((data) => {
              return data;
          })
          .catch((err) => {
              throw new Error(err);
          });
          return this.result;
      } catch (e) {
          throw new Error(e);
      }
  }

  setFlyoutEvents() {
      this.mainNavItems.forEach((item) => {
          item.addEventListener('mouseenter', (e) => {
              this.categories.forEach((category) => {
                  if (category.hasSubcategories && category.id === e.target.getAttribute('data-flyout')) {
                      this.setComponentAttribute(e);
                  }
              });
          });
      });
  }

  setComponentAttribute(event) {
      this.activeCategory = event.target.getAttribute('data-flyout');
      this.setAttribute('data-active-category', this.activeCategory);
  }

  renderFlyout(activeCategory) {
      this.categories.forEach(async (category) => {
          if (category.id === activeCategory) {
              this.flyoutHtml = await this.flyoutTemplate(category);
              this.activeMenuItem = this.querySelector(`[data-flyout="${this.getAttribute('data-active-category')}"]`);
              if (this.activeMenuItem.querySelector('.flyout') === null) {
                  this.activeMenuItem.insertAdjacentHTML('beforeend', this.flyoutHtml);
              }
          }
      });
  }

  removeFlyout() {
      this.querySelectorAll('[data-flyout] .flyout').forEach((flyout) => { flyout.remove(); });
  }

  async flyoutTemplate(activeCategory) {
      this.subcategories = activeCategory.subcategories;
      return `
          <section class="flyout">
              <ul class="column no-bullets categories-1">
                  <li class="flyout-item" interaction="flyout_${activeCategory.title}">
                      ${activeCategory.column1Link
                        ? `<a class="element-link-standard-variant active-category" target="${activeCategory.column1Target}" href="${activeCategory.column1Link}">${activeCategory.column1Title}</a>`
                        : `<span class="element-text-standard-strong info">${activeCategory.column1Title}</span>`
                      }
                  </li>
                  ${this.subcategories.map((subcategory) => {
                      this.tmp = ``;
                      if (subcategory.isColumnOne) {
                        this.tmp = `
                          <li class="flyout-item" interaction="flyout_${activeCategory.title}">
                            <a class="element-link-standard-variant flyout-link" target="${subcategory.target}" href="${subcategory.uri}">${subcategory.title}</a>
                          </li>
                        `;
                      }
                      return this.tmp;
                  }).join(' ')}
              </ul>
              <ul class="column no-bullets categories-2">
                  <li class="flyout-item" interaction="flyout_${activeCategory.title}">
                      ${activeCategory.column2Link
                        ? `<a class="element-link-standard-variant active-category" target="${activeCategory.column2Target}" href="${activeCategory.column2Link}">${activeCategory.column2Title}</a>`
                        : `<span class="element-text-standard-strong info">${activeCategory.column2Title}</span>`
                      }
                  </li>
                  ${this.subcategories.map((subcategory) => {
                      this.tmp = ``;
                      if (subcategory.isColumnTwo) {
                        this.tmp = `
                          <li class="flyout-item" interaction="flyout_${activeCategory.title}">
                            <a class="element-link-standard-variant flyout-link" target="${subcategory.target}" href="${subcategory.uri}">${subcategory.title}</a>
                          </li>
                        `;
                      }
                      return this.tmp;
                  }).join(' ')}
              </ul>
              <ul class="column no-bullets extras">
                  ${this.subcategories.map((subcategory) => {
                      this.tmp = ``;
                      if (subcategory.isArticle) {
                          const artikel = subcategory.artikelModel
                          let grundpreisElement = '';
                          if (artikel.grundpreis) {
                              let ausgangsmenge = '';
                              if (artikel.ausgangsmenge) {
                                  ausgangsmenge = artikel.ausgangsmenge + ' ';
                              }
                              grundpreisElement = `<p class="element-text-small artikel-grundpreis">${ausgangsmenge}${artikel.grundpreis}</p>`;
                          }
                          let preisElement = `<p class="element-text-large artikel-preis">${artikel.verkaufspreis}</p>`;
                          if (artikel.streichpreis) {
                              let streichpreis = '';
                              if (artikel.streichpreisart) {
                                  streichpreis = '<span class="element-text-small artikel-streichpreisart">' + artikel.streichpreisart + '</span>';
                                  streichpreis += '&nbsp;<span class="element-text-small artikel-streichpreis">' + artikel.streichpreis + '</span>';
                              } else {
                                  streichpreis = '<s>' + artikel.streichpreis + '</s>';
                              }
                              preisElement = `
                                      <p class="element-text-large-strong-sale artikel-preis">
                                          ${artikel.verkaufspreis}
                                          ${streichpreis}
                                          <span class="element-text-standard">${artikel.hasPreisHinweis ? "*" : ""}</span>
                                      </p>`;
                          }
                          let ratingElement = '';
                          if (artikel.bewertungsStatistik && artikel.bewertungsStatistik.total > 0) {
                            ratingElement = `<p class="artikel-rating-container"><span class="element-rating-standard artikel-rating" rating="${artikel.bewertungsStatistik.sterneHalb}"></span><span class="element-text-standard">(${artikel.bewertungsStatistik.total})</span></p>`;
                          }
                          this.tmp = `
                              <li class="is-article" impression="product">
                                  <a class="layered-link" target="${artikel.artikelDetailSeiteUrl.target}" href="${artikel.artikelDetailSeiteUrl.ziel}" product-price="${artikel.trackingPreis}" product-avail="${artikel.meldeschluessel}">
                                      <dl-product product-id="${artikel.matNr}" name="${artikel.titel}" price="${artikel.trackingPreis}" category="${artikel.fuehrendeKategoriePfad}"></dl-product>
                                      ${artikel.titel}
                                  </a>
                                  <picture class="article-picture">
                                      <img class="article-image" src="${artikel.coverBildUrlMap['listeGross'].uri}" alt="">
                                  </picture>    
                                  <section class="article-info">
                                      <h2 class="element-headline-medium article-title">${artikel.titel}</h2>
                                      <p class="element-text-standard article-subtitle">${artikel.autor ? artikel.autor.name : ""}</p>
                                      ${preisElement}
                                      ${grundpreisElement}
                                      ${ratingElement}
                                  </section>
                              </li>
                          `;
                      } else if (subcategory.isTeaser) {
                          this.tmp = `
                              <li class="is-article" impression="promotion" promotion-name="${subcategory.title}" promotion-creative="flyout_${activeCategory.title}">
                                  <a class="layered-link" target="${subcategory.target}" href="${subcategory.uri}">${subcategory.title}</a>
                                  <picture class="article-picture">
                                      <img class="article-image" src="${subcategory.image}" alt="">
                                  </picture>
                                  <section class="article-info">
                                      <h2 class="element-headline-medium article-title">${subcategory.title}</h2>
                                      <p class="element-text-standard article-subtitle">${subcategory.subtitle}</p>
                                      <a class="element-link-standard-variant-negative-strong button-more" href="${subcategory.uri}">${subcategory.callout}</a>
                                  </section>
                              </li>
                          `;
                      }
                      return this.tmp;
                  }).join(' ')}
              </ul>
          </section>
      `;
  }

  handleClickNext() {
      if ('scrollBehavior' in document.documentElement.style) {
          this.mainNav.scrollTo({
              top: 0,
              left: this.mainNav.scrollLeft + this.mainNav.clientWidth,
              behavior: 'smooth'
          });
      } else {
          this.mainNav.scrollLeft += this.mainNav.clientWidth;
      }
  }

  handleClickPrev() {
      if ('scrollBehavior' in document.documentElement.style) {
          this.mainNav.scrollTo({
              top: 0,
              left: this.mainNav.scrollLeft - this.mainNav.clientWidth,
              behavior: 'smooth'
          });
      } else {
          this.mainNav.scrollLeft -= this.mainNav.clientWidth;
      }
  }

  observeControls() {
      this.navItemFirst = this.mainNav.querySelector('.item:first-of-type');
      this.navItemLast = this.mainNav.querySelector('.item:last-of-type');
      this.options = {
          root: this.navigation,
          threshold: [0.8, 1]
      };
      this.observer = new IntersectionObserver((entries) => {
          this.resetControllVisibility();
          entries.forEach((entry) => {
              if (entry.target === this.navItemFirst && entry.isIntersecting && entry.intersectionRatio >= 1) {
                  this.prev.style.display = 'none';
              }
              if (entry.target === this.navItemLast && entry.isIntersecting && entry.intersectionRatio >= 1) {
                  this.next.style.display = 'none';
              }
              this.updateVisibiltyThruScrollPos();
          });
      }, this.options);
      this.observer.observe(this.navItemFirst);
      this.observer.observe(this.navItemLast);
  }

  static isDesktop() {
      return window.matchMedia('(min-width: 801px)');
  }
}

if (!customElements.get('header-prime-navigation')) {
  customElements.define('header-prime-navigation', MainNavigation);
}
